<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Status"
          >
            <b-form-select
              v-model="active"
              :options="[
                { value: null, text: 'All' },
                { value: true, text: 'Active' },
                { value: false, text: 'Non-Active' },
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label="Tipe Kontak"
          >
            <b-form-checkbox-group
              v-model="selectedCheckedBox"
              :options="options"
              class="mb-3"
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            class="mr-1"
            size="sm"
            variant="primary"
            type="button"
            @click.prevent="show"
          >Show</b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="danger"
            type="button"
            @click.prevent="reset"
          >Reset</b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-add="false"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            Filter
          </b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol,BFormCheckboxGroup } from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,

    BFormSelect,
    BRow,
    BCol,
    BFormCheckboxGroup,
  },
  data() {
    return {
      dataurl:"/contact",
      baseroute:"contact",
      title:"Semua Contact",
      fields: [
        { key: 'region.name', label: 'region', sortable: true },
        { key: 'code', label: 'code', sortable: true },
        { key: 'name', label: 'name', sortable: true },
        { key: 'address', label: 'address', sortable: true },
        { key: 'city', label: 'kota', sortable: true },
        { key: 'phone', label: 'telp', sortable: true }
      ],
      showFilter : true,
      filter :[],
      selectedCheckedBox:[],
      options: [
        { name: 'Karyawan', id: 'karyawan' },
        { name: 'Vendor', id: 'vendor' },
        { name: 'Bengkel', id: 'bengkel' },
        { name: 'Supplier', id: 'supplier' },
        { name: 'Pelanggan', id: 'pelanggan' },
        { name: 'Asuransi', id: 'asuransi' },
      ],
      active:null,
    }
  },
  methods:{
    show(){
      // this.filter['filter[active]']=this.active
      // this.filter['filter[region_id]']=this.region.id
      this.$refs.basetable.fetchData()
    },
    reset(){
      this.selectedCheckedBox = []
      this.active = null
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
